<template>
  <div class="login-layout">
    <div class="main-welcome">
      <img class="logo" src="@/assets/img/login/logo-white.png" alt="logo" />
    </div>
    <div class="page-wrap">
      <div class="page-inner">
        <img class="mobile-logo" src="@/assets/img/header/logo.png" alt="logo" />
        <h1 class="title">Dahua Partner Alliances</h1>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.login-layout {
  height: 100%;
  .main-welcome {
    width: 37.5%;
    height: 100%;
    background: url('~@/assets/img/login/login-main-pic.png') center center;
    float: left;
    position: relative;
    .logo {
      max-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .page-wrap {
    overflow: hidden;
    height: 100%;
    display: flex;
    overflow-y: auto;
    .page-inner {
      width: 480px;
      margin: auto;
      padding: 24px 0;
			.mobile-logo{
				display: none;
			}
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #303133;
        line-height: 48px;
        margin-bottom: 36px;
      }
    }
  }
  @media screen and (max-width:768px) {
    padding: 0 20px;
    .main-welcome{
      display: none;
    }
    .page-wrap {
      display: block;
      .page-inner {
        width: 100%;
        .mobile-logo{
          display: block;
          margin: 15px auto;
          width: 100px;
        }
        .title{
          font-size: 24px;
          line-height: 32px;
          padding-top: 40px;
          margin-bottom: 31px;
        }
      }
    }
  }
}
</style>
