<template>
  <div class="header-pc">
    <div class="header-container clearfix">
      <router-link to="/" class="logo-box fl">
        <img class="logo-img" src="@/assets/img/header/logo.png" alt="" />
      </router-link>
      <div class="nav-box fl">
        <ul v-if="headerNav && headerNav.length" class="nav-list">
          <!-- Solutions -->
          <li
            class="nav-item"
            :class="{active: currentIndex === 0}"
            @mouseenter="handleEnter(0, $event)"
            @mouseleave="handleLeave($event)"
          >
            <router-link to="/solutions_services" class="nav-name">{{ headerNav[0].nav_name }}</router-link>
            <el-collapse-transition>
              <div v-if="showDropDown === 0" class="solution-drop-down">
                <div class="solution-box container">
                  <p class="introduction-text" v-html="headerNav[0].nav_description"></p>
                  <div class="see-more">
                    <router-link to="/solutions_services">
                      <el-button type="primary">{{ headerNav[0].nav_content }}</el-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </li>
          <!-- Partners -->
          <li
            class="nav-item"
            :class="{active: currentIndex === 1}"
            @mouseenter="handleEnter(1, $event)"
            @mouseleave="handleLeave($event)"
          >
            <router-link to="/partners" class="nav-name">{{ headerNav[1].nav_name }}</router-link>
            <el-collapse-transition>
              <div v-if="showDropDown === 1" class="solution-drop-down">
                <div class="partners-box container">
                  <ul class="partners-container">
                    <li v-for="(item, index) in headerNav[1].child" :key="index" class="partners-item">
                      <a @click="handleLink(item)">
                        <p class="partners-text">{{ item.nav_name }}</p>
                        <div class="partners-img">
                          <img class="img-box" :src="item.nav_image" alt="" />
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </el-collapse-transition>
          </li>
          <!-- Partner Program -->
          <li
            class="nav-item"
            :class="{active: currentIndex === 2}"
            @mouseenter="handleEnter(2, $event)"
            @mouseleave="handleLeave($event)"
          >
            <router-link to="/partnerPrograms" class="nav-name">{{ headerNav[2].nav_name }}</router-link>
            <el-collapse-transition>
              <div v-if="showDropDown === 2" class="solution-drop-down">
                <div class="program-box">
                  <ul class="program-container container">
                    <li v-for="(item, index) in headerNav[2].child" :key="index" class="program-item">
                      <h5 class="program-title no-pointer">{{ item.nav_name }}</h5>
                      <div class="program-child-list">
                        <div v-for="(itemChild, indexChild) in item.child" :key="indexChild" class="program-child-item">
                          <a class="link" @click="handleLink(itemChild)">{{ itemChild.nav_name }}</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-collapse-transition>
          </li>
          <!-- Events -->
          <li
            class="nav-item"
            :class="{active: currentIndex === 3}"
            @mouseenter="handleEnter(3, $event)"
            @mouseleave="handleLeave($event)"
          >
            <router-link to="/events" class="nav-name">{{ headerNav[3].nav_name }}</router-link>
            <el-collapse-transition>
              <div v-if="showDropDown === 3" class="solution-drop-down">
                <div class="partners-box events-box">
                  <ul class="partners-container">
                    <li v-for="(item, index) in headerNav[3].child" :key="index" class="partners-item">
                      <a @click="handleLink(item)">
                        <p class="partners-text">{{ item.nav_name }}</p>
                        <div class="partners-img">
                          <img class="img-box" :src="item.nav_image" alt="" />
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </el-collapse-transition>
          </li>
          <!-- Integration Protocols -->
          <li
            class="nav-item"
            :class="{active: currentIndex === 4}"
            @mouseenter="handleEnter(4, $event)"
            @mouseleave="handleLeave($event)"
          >
            <router-link to="/integration" class="nav-name">{{ headerNav[4].nav_name }}</router-link>
            <el-collapse-transition>
              <div v-if="showDropDown === 4" class="solution-drop-down">
                <div class="program-box protocols-box">
                  <ul class="program-container container">
                    <li v-for="(item, index) in headerNav[4].child" :key="index" class="program-item">
                      <h5 class="program-title" @click="handleLink(item)">{{ item.nav_name }}</h5>
                      <div class="program-child-list">
                        <div v-for="(itemChild, indexChild) in item.child" :key="indexChild" class="program-child-item">
                          <a class="link" @click="handleLink(itemChild)">{{ itemChild.nav_name }}</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-collapse-transition>
          </li>
          <!-- Support -->
          <li
            v-if="headerNav[5]"
            class="nav-item"
            :class="{active: currentIndex === 5}"
            @mouseenter="handleEnter(5, $event)"
            @mouseleave="handleLeave($event)"
          >
            <router-link to="/support/getSupport" class="nav-name">{{ headerNav[5].nav_name }}</router-link>
            <el-collapse-transition>
              <div v-if="showDropDown === 5" class="solution-drop-down">
                <div class="solution-box container">
                  <p class="introduction-text">{{ headerNav[5].nav_name_alias }}</p>
                  <div class="see-more">
                    <router-link to="/support/getSupport">
                      <el-button type="primary">{{ headerNav[5].nav_content }}</el-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </li>
        </ul>
      </div>
      <div class="info-box fr">
        <ul class="info-list">
          <!-- <li class="info-item">
            <p class="iconfont icon-a-search1x"></p>
          </li> -->
          <li class="info-item">
            <router-link v-if="!isToken" slot="reference" to="/login" class="iconfont icon-a-gerenzhongxin1x" />
            <!-- 登录 -->
            <el-popover
              v-else
              width="128"
              trigger="hover"
              popper-class="login-success"
            >
              <router-link slot="reference" to="/mypage" class="iconfont icon-a-gerenzhongxin-logged1x login-success-icon" />
              <ul class="login-select">
                <li class="select-item">
                  <router-link class="link" to="/mypage">{{ LP.lang_my_page }}</router-link>
                </li>
                <li class="select-item">
                  <router-link class="link" to="/login/forgotpassword">{{ LP.lang_change_password }}</router-link>
                </li>
                <li class="select-item" @click="exit">{{ LP.lang_log_out }}</li>
              </ul>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    // 头部导航数据
    headerNav: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentIndex: -1, // 当前选中导航栏
      showDropDown: -1 // 显示当前菜单下拉
    }
  },
  computed: {
    ...mapState({
      isToken: (state) => state.login.token // token信息
    })
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.showDropDown = -1
      } else if (to.query !== from.query) {
        this.showDropDown = -1
      }
    }
  },
  methods: {
    // 点击导航栏
    handleTabs(item, index) {
      this.currentIndex = index
    },
    // 鼠标移入菜单
    handleEnter(index, e) {
      this.showDropDown = index
      const name = e.currentTarget.firstElementChild
      name.style.fontWeight = 'bold'
    },
    // 鼠标移出菜单
    handleLeave(e) {
      this.showDropDown = -1
      const name = e.currentTarget.firstElementChild
      name.style.fontWeight = 'normal'
    },
    // 导航跳转
    handleLink(item) {
      if (item.nav_linktype === '1') {
        if(item.nav_id == "36"){
          this.$router.push("errorPage/404")
        }else{
          this.$router.push(`${item.nav_link_name}`)
        }
        
      } else {
        window.open(item.nav_linkurl)
      }
    },
    // 退出登录
    exit() {
      this.$store.dispatch('login/logout')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.header-pc {
  padding: 0 100px 0 75px;
  background: #FFFFFF;
  border-bottom: 1px solid #DCDFE6;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  .header-container {
    height: 80px;
    max-width: 1770px;
    margin: auto;
    .logo-box {
      max-width: 100px;
      line-height: 80px;
      .logo-img {
        max-width: 100%;
      }
    }
    .nav-box {
      margin-left: 18%;
      .nav-list {
        display: flex;
        .nav-item {
          padding: 0 24px;
          .transition();
          .nav-name {
            font-size: 16px;
            color: #303133;
            position: relative;
            display: block;
            font-size: 16px;
            color: #333;
            line-height: 80px;
            cursor: pointer;
            &::after {
              content: "";
              width: 0;
              height: 3px;
              background: #D8D8D8;
              position: absolute;
              left: 50%;
              bottom: 0px;
              z-index: 2;
              transition: all 0.6s ease;
            }
          }
          &:hover {
            .nav-name {
              &::after {
                left: 0;
                width: 100%;
              }
            }
          }
          &.active {
            .nav-name {
              font-weight: 600;
              &::after {
                left: 0;
                width: 100%;
              }
            }
          }
          .solution-box {
            text-align: center;
            padding: 50px 0;
            .introduction-text {
              font-size: 18px;
              color: #303133;
              line-height: 24px;
            }
            .see-more {
              margin-top: 40px;
              line-height: 44px;
              /deep/ .el-button--primary {
                background: #319BD8;
                border-color: #319BD8;
              }
            }
          }
          .partners-box {
            .partners-container {
              display: flex;
              justify-content: center;
              padding: 48px 0;
              .partners-item {
                width: calc(33.333333% - 6px);
                max-width: 308px;
                margin-right: 16px;
                text-align: center;
                cursor: pointer;
                &:nth-child(3n) {
                  margin-right: 0;
                }
                .partners-text {
                  font-size: 16px;
                  font-weight: bold;
                  color: #303133;
                  line-height: 21px;
                  .transition();
                }
                .partners-img {
                  max-width: 80px;
                  margin: 24px auto 0;
                  .img-box {
                    max-width: 100%;
                    height: 100%;
                  }
                }
                &:hover {
                  .partners-text {
                    color: @theme;
                  }
                }
              }
            }
          }
          .program-box {
            .program-container {
              display: flex;
              justify-content: center;
              padding: 48px 0;
              .program-item {
                width: 33.333333%;
                margin-right: 16px;
                &:nth-child(3n) {
                  margin-right: 0;
                }
                .program-title {
                  font-size: 16px;
                  font-weight: bold;
                  color: #333333;
                  line-height: 21px;
                  margin-bottom: 24px;
                  cursor: pointer;
                  &.no-pointer {
                    cursor: default;
                  }
                }
                .program-child-list {
                  .program-child-item {
                    margin-bottom: 16px;
                    .link {
                      font-size: 16px;
                      color: #333333;
                      line-height: 21px;
                      cursor: pointer;
                      .transition;
                      &:hover {
                        color: @theme;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .solution-drop-down {
          width: 100%;
          background: #FFFFFF;
          box-shadow: 0px 3px 10px 0px rgba(214, 214, 214, 0.38);
          position: absolute;
          top: 80px;
          left: 0;
        }
      }
    }
    .info-box {
      line-height: 80px;
      .info-list {
        display: flex;
        .info-item {
          padding: 0 12px;
          cursor: pointer;
          .iconfont {
            display: block;
            padding: 0 12px;
            font-size: 20px;
            &.login-success-icon {
              color: @theme;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .header-container {
      .nav-box {
        margin-left: 12%;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    padding: 0 24px;
    .header-container {
      .nav-box {
        .nav-list {
          .nav-item {
            padding: 0 16px;
            .nav-name {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .header-container {
      .nav-box {
        margin-left: 8%;
        .nav-list {
          .nav-item {
            padding: 0 12px;
            .nav-name {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.login-success {
  .login-select {
    .select-item {
      padding: 12px 6px;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      .transition();
      & + .select-item {
        border-top: 1px solid #E4E7ED;
      }
      &:hover {
        color: @theme;
        cursor: pointer;
        .link {
          color: @theme;
        }
      }
      .link {
        display: block;
      }
    }
  }
}
</style>
<style lang="less">
.login-success {
  padding: 0;
  top: 55px !important;
}
</style>
