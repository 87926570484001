<template>
  <public-dialog
    :show-cancel="false"
    :show-submit="false"
    :visible="visible"
    :element-config="elementUsed"
    @cancel="cancel"
  >
    <div class="info-wrap ">
      <div class="info-form container ">
        <el-form
          ref="form"
          v-loading="loading"
          element-loading-text="loading..."
          :inline="true"
          :model="form"
          :rules="rules"
          :label-position="labelPosition"
        >
          <!-- 表单信息部分 -->
          <div class="form-item">
            <div class="form-item-title">
              <div class="circular">1</div>
              <span class="all-title">{{ LP.lang_basic_information }}</span>
            </div>
            <div class="form-content">
              <el-form-item :label="LP.lang_first_name" prop="firstName">
                <el-input v-model="form.firstName" placeholder="First Name" />
              </el-form-item>
              <el-form-item :label="LP.lang_last_name" prop="lastName">
                <el-input v-model="form.lastName" placeholder="Last Name" />
              </el-form-item>
              <el-form-item :label="LP.lang_email" prop="email">
                <el-input v-model="form.email" placeholder="Email" />
              </el-form-item>
              <el-form-item :label="LP.lang_phone_number" prop="phoneNumber">
                <el-input v-model="form.phoneNumber" placeholder="Phone Number" />
              </el-form-item>
              <el-form-item :label="LP.lang_dipp_company_name" prop="companyName">
                <el-input v-model="form.companyName" placeholder="Company Name" />
              </el-form-item>
              <el-form-item :label="LP.lang_company_address" prop="companyAddress">
                <el-input v-model="form.companyAddress" placeholder="Company Address" />
              </el-form-item>
              <el-form-item :label="LP.lang_job_title" prop="jobTitle">
                <el-input v-model="form.jobTitle" placeholder="Job Title" />
              </el-form-item>
              <el-form-item :label="LP.lang_country_region" prop="country">
                <el-select v-model="form.country" filterable placeholder="Select">
                  <el-option
                    v-for="(item, index) in countryList"
                    :key="index"
                    :label="item.region_name"
                    :value="item.region_id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <!-- 信息部分 -->
          <div class="form-item">
            <div class="form-item-title">
              <div class="circular">2</div>
              <span class="all-title">{{ LP.lang_business_information }}</span>
            </div>
            <div class="text-content ">
              <el-form-item :label="LP.lang_message" prop="desc">
                <el-input v-model="form.desc" class="text" type="textarea" :rows="8" placeholder="Message" />
              </el-form-item>
            </div>
          </div>
          <!-- 验证部分 -->
          <div class="form-item">
            <div class="form-item-title">
              <div class="circular">3</div>
              <span class="all-title">{{ LP.lang_authentication }}</span>
            </div>
            <div class="code-content">
              <el-form-item :label="LP.lang_authentication_code" prop="captcha">
                <div class="code-box">
                  <el-input v-model="form.captcha" class="code-input" placeholder="please enter authentication code" />
                  <div v-loading="codeLoading" class="code">
                    <img :src="src" onClick="this.src=this.src+'?'" style="cursor:pointer;" />
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="contract">
              {{ LP.lang_privacy }}
              <a href="https://www.dahuasecurity.com/aboutUs/privacy-policy" target="_blank" class="text">{{ LP.lang_privacy_policy }}</a>.
            </div>
            <div class="contract contract-agree">
              {{ LP.lang_terms_use }}
              <a href="https://www.dahuasecurity.com/aboutUs/terms-of-use" target="_blank" class="text">{{ LP.lang_terms_of_use	}}</a>
              {{ LP.lang_and }}
              <a href="https://www.dahuasecurity.com/aboutUs/privacy-policy" target="_blank" class="text">{{ LP.lang_privacy_policy }}</a>.
            </div>
            <el-checkbox v-model="checked" :rules="rules">{{ LP.lang_agree }} <span class="xing">*</span></el-checkbox>
          </div>
        </el-form>
        <!-- 按钮 -->
        <div class="info-btn">
          <el-button type="info" class="info-btn-reset btn" @click="reset('form')">{{ LP.lang_reset }}</el-button>
          <el-button type="info" class="info-btn-send btn" plain @click="send('form')">{{ LP.lang_send }}</el-button>
        </div>
      </div>
    </div>
  </public-dialog>

</template>

<script>
import PublicDialog from '@/components/public/PublicDialog'
import { regionApi, contactUsApi } from '@/api/contactUs'
import { _axios } from '@/plugins/axios.js'
export default {
  components: {
    PublicDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      elementUsed: {
        title: ''
      },
      labelPosition: 'top', // 表单标签位置
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        companyAddress: '',
        jobTitle: '',
        country: '',
        desc: '',
        captcha: ''
      },
      checked: false, // 复选框默认为不选
      src: _axios.defaults.baseURL + '/user/verification_code', // 验证码图片地址
      codeLoading: false, // 验证码加载loading
      countryList: [], // 选择城市
      loading: false,
      // 表单校验规则
      rules: {
        firstName: [
          { required: true, message: 'First Name is required', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Last Name is required', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { type: 'email', message: 'Email is not a valid email', trigger: ['blur', 'change'] }
        ],
        companyName: [
          { required: true, message: 'Company Name is required', trigger: 'blur' }
        ],
        CompanyAddress: [
          { required: true, message: 'Company Address Name is required', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'Company Address is required', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Message is required', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: 'Authentication Code is required', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getRegionApi()
  },
  methods: {
    // 关闭弹窗
    cancel() {
      this.$emit('cancel')
      this.checked = false
      this.$refs.form.resetFields()
      this.src = this.src + '?'
    },
    // 国家接口
    getRegionApi() {
      regionApi().then(res => {
        this.countryList = res.data
      })
    },
    // 重置按钮
    reset(formName) {
      this.$refs[formName].resetFields()// 清空列表
      this.src = this.src + '?'// 更新验证码
      this.checked = false// agree复选框为默认状态
    },
    // 发送按钮
    send(formName) {
      if (!this.checked) {
        this.$alert('Please check the privacy agreement first', {
          confirmButtonText: 'confirm'
        })
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            contactUsApi({
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              telephone: this.form.phoneNumber,
              email: this.form.email,
              company_name: this.form.companyName,
              company_address: this.form.companyAddress,
              job_title: this.form.jobTitle,
              region: this.form.country,
              message: this.form.desc,
              verifyCode: this.form.captcha
            }).then(res => {
              if (res.status === '00') {
                this.$message.success('Sent successfully! Thank you for your submission.')
                this.loading = false
                this.cancel()
              }
            })
              .catch(err => {
                this.$message.error(err.data.message)
                this.loading = false
                this.src = this.src + '?'
              })
          } else {
            this.src = this.src + '?'
            return false
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.info-wrap{
  padding: 50px 0;
  .info-form{
    .form-item{
      width: 100%;
      overflow-x: hidden;
      .form-item-title{
        margin-bottom: 20px;
        .circular{
          width: 40px;
          height: 40px;
          display: inline-block;
          border-radius: 50%;
          line-height: 40px;
          font-size: 14px;
          color: #ffffff;
          background-color: #319BD8;
          text-align: center;
          vertical-align: middle;
        }
        .all-title{
          display: inline-block ;
          font-size: 24px ;
          line-height: 40px;
          color: #333;
          margin-left: 1%;
          vertical-align: middle;
          z-index: 9999999999;
        }
      }
      .form-content{
        /deep/ .el-form-item{
          width: 48%;
        }
        /deep/.el-select{
          width: 100%;
        }
      }
      .text-content{
        /deep/ .el-form-item{
          width: 100%;
        }
      }
      .code-content{
        /deep/ .el-form-item{
          width: 100%;
        }
      }
      .code-box{
        display: flex;
        .code-input{
        width: 30% ;
        margin-right: 4.2%;
        }
        .code{
          width: 17%;
          height: 40px;
          cursor: pointer;
          .code-img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .contract{
        .text{
          color:#0e5fac ;
          text-decoration: underline;
        }
      }
      .contract-agree{
        margin: 15px 0;
      }
      .xing{
        color: red;
      }
    }
    .info-btn{
      width: 100%;
      text-align: center;
      margin: 50px 0 ;
      .btn{
        width: 20%;
        border-radius: 20px;
        font-size: 16px;
      }
      .info-btn-reset{
        color: #333;
      }
      .info-btn-send{
        color: #ffffff;
        background-color: #319BD8;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .info-form{
      .form-item{
        .form-item-title{
          margin-bottom: 16px;
          .circular{
            width: 30px;
            height: 30px;
            display: inline-block;
            border-radius: 50%;
            line-height: 30px;
            font-size: 16px;
            color: #ffffff;
            background-color: #319BD8;
            text-align: center;
            vertical-align: middle;
          }
          .all-title{
            display: inline-block ;
            font-size: 20px ;
            line-height: 30px;
            color: #333;
            margin-left: 1%;
            vertical-align: middle;
            z-index: 9999999999;
          }
        }
        .form-content{
          /deep/ .el-form-item{
            width: 100%;
          }
          /deep/.el-select{
            width: 100%;
          }
        }
        .code-box{
          display: flex;
          flex-wrap: wrap;
          .code-input{
            width: 100% ;

          }
          .code{
            width: 33.3%;
            height: 40px;
            cursor: pointer;
            .code-img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .info-btn{
        width: 100%;
        .btn{
          width: 40% ;
          border-radius: 20px;
          font-size: 16px;
        }
      }
    }
    /deep/.el-form--inline .el-form-item__label{
      padding: 0;
    }
  }
}
</style>
<style lang="less">
// 移动端消息提示
@media only screen and (max-width: 768px){
  .el-message-box {
    width: 90%;
  }
}
//消息弹窗
.el-message{
  z-index: 9999999 !important;
}
</style>
