<template>
  <div id="default-layout">
    <!-- 右侧固定菜单栏 -->
    <right-flex />
    <the-header />
    <div class="page">
      <router-view />
    </div>
    <the-footer />
  </div>
</template>

<script>
import theHeader from '@/components/layout/header/Index'
import theFooter from '@/components/layout/Footer'
import RightFlex from '@/components/layout/RightFlex.vue'
export default {
  components: {
    RightFlex,
    theHeader,
    theFooter
  }
}
</script>

<style lang="less" scoped>
#default-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  .page {
    flex: 1 0 auto;
  }
}
</style>
