<template>
  <div class="header-mobile">
    <div class="header-container container">
      <!-- logo -->
      <a href="" class="logo-box fl">
        <img class="logo-img" src="@/assets/img/header/logo.png" alt="" />
      </a>
      <!-- 下拉菜单 -->
      <ul class="mobile-nav fr clearfix">
        <li
          class="header-info--full header-mobile-nav visible-sm"
          :class="{ active: showMobileList }"
          @click="toggleShow"
        >
          <div class="mobile-nav-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 菜单列表 -->
    <div v-show="showMobileList" class="header-slide-down">
      <header-slide-down :list="headerNav" />
    </div>
  </div>
</template>

<script>
import HeaderSlideDown from '@/components/layout/header/HeaderSlideDown.vue'
export default {
  components: {
    HeaderSlideDown
  },
  props: {
    // 头部导航数据
    headerNav: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showMobileList: false // 是否显示一级菜单
    }
  },
  watch: {
    // 监听路由变化，当路由变化关闭下拉面板
    $route(to, from) {
      if (to.path !== from.path) {
        this.showMobileList = false
      } else if (to.query !== from.query) {
        this.showMobileList = false
      }
    }
  },
  methods: {
    // 点击导航按钮
    toggleShow() {
      this.showMobileList = !this.showMobileList
    }
  }
}
</script>

<style lang="less" scoped>
.header-mobile {
  .header-container {
    padding: 0 20px;
    background: #FFFFFF;
    border-bottom: 1px solid #DCDFE6;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    .logo-box {
      max-width: 100px;
      line-height: 60px;
      .logo-img {
        max-width: 100%;
      }
    }
    .mobile-nav {
      max-width: 50%;
      float: right;
      & > li {
        float: left;
        position: relative;
        font-size: 14px;
        z-index: 99;
        .mobile-nav-icon {
          width: 28px;
          height: 19px;
          margin: 21px auto;
          position: relative;
          span {
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            background-color: #909399;
            border-radius: 4px;
            -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
          }
          span:first-child {
            top: 0;
            top: 50%;
          }
          span:nth-child(2) {
            bottom: -2px;
          }
        }
      }
      .header-info--full {
        margin-left: 1px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        float: right;
        -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      }
      .header-mobile-nav {
        width: 50px;
        height: 100%;
        &.active {
          transition: all 0.8s;
          .mobile-nav-icon {
            span:first-child {
              transform: rotate(45deg) translateY(-50%);
              transform-origin: center;
              top: 50%;
            }
            span:nth-child(2) {
              opacity: 0;
            }
            span:last-child {
              transform: rotate(-45deg) translateY(-50%);
              transform-origin: center;
              top: 50%;
            }
          }
        }
      }
    }
  }
  .header-slide-down {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-top: 1px solid #eee;
    overflow-y: auto;
    z-index: 98;
  }
}
</style>
