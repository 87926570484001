<template>
  <div class="footer-wrap">
    <div class="footer-nav">
      <div class="nav-container container">
        <div class="nav-name">
          <p class="name">{{ LP.lang_smarter_together }}</p>
        </div>
        <ul class="nav-list">
          <li v-for="(item, index) in footerNav.dahua_message" :key="index" class="nav-item">
            <a :href="item.url" class="link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="bottom-container container">
        <div class="case-number">
          <p class="data">{{ footerNav.dahua_technology_time }}</p>
          <div class="record-box">
            <a :href="footerNav.beian_one_url" class="link">{{ footerNav.beian_one }}</a>
            <a :href="footerNav.beian_two_url" class="link">{{ footerNav.beian_two }}</a>
          </div>
        </div>
        <ul class="partner-list">
          <li v-for="(item, index) in footerNav.follow" :key="index" class="partner-item">
            <a
              class="partner-link"
              :href="item.follow_linkurl"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img :src="item.follow_image" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getFooterApi } from '@/api/home.js'
export default {
  data() {
    return {
      footerNav: {} // 底部导航数据
    }
  },
  mounted() {
    this.getFooterNav()
  },
  methods: {
    // 获取底部导航接口
    getFooterNav() {
      getFooterApi().then((res) => {
        this.footerNav = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footer-wrap {
  background: #232931;
  .footer-nav {
    color: #f5f5f5;
    padding: 22px 0 18px 0;
    border-bottom: 1px solid #606266;
    .nav-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav-name {
        .name {
          font-size: 18px;
          font-weight: bold;
          color: #F5F5F5;
          line-height: 24px;
        }
      }
      .nav-list {
        display: flex;
        .nav-item {
          position: relative;
          .link {
            font-size: 16px;
            color: #F5F5F5;
            line-height: 24px;
            padding: 0 24px;
          }
          & + ::before {
            display: block;
            width: 1px;
            height: 18px;
            content: '';
            background: #606266;
            position: absolute;
            left: 0;
            top: 3px;
          }
        }
      }
    }
  }
  .footer-bottom {
    padding: 22px 0 30px 0;
    .bottom-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .case-number {
        font-size: 16px;
        color: #606266;
        line-height: 21px;
        .data {
          margin-bottom: 16px;
        }
        .record-box {
          display: flex;
          align-items: center;
          .link {
            color: #606266;
            & + .link {
              margin-left: 30px;
            }
          }
        }
      }
      .partner-list {
        display: flex;
        .partner-item {
          & + .partner-item {
            margin-left: 24px;
          }
          .partner-link {
            &>img {
              width: 30px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .footer-nav {
      .nav-container {
        .nav-name {
          display: none;
        }
        .nav-list {
          flex-wrap: wrap;
          margin: 0 auto;
          .nav-item {
            .link {
              font-size: 14px;
              line-height: 24px;
              padding: 0 6px;
            }
          }
        }
      }
    }
    .footer-bottom {
      .bottom-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .case-number {
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          .data {
            margin-bottom: 14px;
          }
          .record-box {
            justify-content: center;
          }
        }
        .partner-list {
          width: 100%;
          justify-content: center;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
