<template>
  <ul class="mobile-nav-item">
    <li v-for="(item, index) in list" :key="index" class="item-li" @click.stop="handleClick(item, index)">
      <a :class="{ active: showChildList === index }" class="link">
        <span>{{ item.nav_name }}</span>
        <span
          v-if="item.child && item.child.length"
          class="iconfont icon-a-xiala1x"
          :class="{unfold: showChildList === index}"
        ></span>
      </a>
      <!-- 子集菜单 -->
      <el-collapse-transition>
        <header-slide-down
          v-if="item.child && item.child.length"
          v-show="showChildList === index"
          :list="item.child"
        />
      </el-collapse-transition>
    </li>
    <!-- 登录 -->
    <div class="item-li">
      <router-link to="/login" class="link">
        <span>{{ LP.lang_openplatform_login }}</span>
      </router-link>
    </div>
  </ul>
</template>

<script>
export default {
  name: 'HeaderSlideDown',
  props: {
    // 菜单列表
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showChildList: -1 // 是否显示一级菜单
    }
  },
  methods: {
    // 点击导航
    handleClick(item, i) {
      if (item.child && item.child.length) {
        i === this.showChildList ? this.showChildList = -1 : this.showChildList = i
      } else {
        if (item.nav_linktype === '1') {
          this.$router.push(`${item.nav_link_name}`)
        } else {
          window.open(item.nav_linkurl)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mobile-nav-item {
  .item-li {
    .link {
      display: block;
      padding: 0 3%;
      -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
      position: relative;
      height: 36px;
      font-size: 16px;
      line-height: 36px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      &.active {
        color: @theme;
        .unfold {
          transform: rotate(180deg);
        }
      }
    }
		// 子集面板缩进
		.item-li{
			.link{
				padding-left:5%;
			}
			.item-li{
				.link{
					padding-left:7%;
				}
			}
		}
  }
	@media screen and (max-width:768px) {
		.item-li{
			.link{
				padding: 0 4%;
			}
			.item-li{
				.link{
					padding-left:8%;
				}
				.item-li{
					.link{
						padding-left:12%;
					}
				}
			}
		}
	}
}
</style>
