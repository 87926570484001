<template>
  <div class="right-flex">
    <ul class="flex-list">
      <li v-for="(item, index) in list" :key="index" class="flex-item">
        <div class="link" @click="openInformation">
          <span class="iconfont" :class="item.icon"></span>
          <p class="name ellipsis">{{ item.name }}</p>
        </div>
      </li>
    </ul>
    <information-desk :visible="visible" @cancel="cancel" />
  </div>
</template>

<script>

import InformationDesk from '@/components/layout/InformationDesk.vue'
export default {
  components: {
    InformationDesk
  },
  data() {
    return {
      visible: false, // 弹框默认关闭
      list: [
        {
          icon: 'icon-a-icon-contact1x',
          name: 'Contact Us'
        }
      ]
    }
  },
  methods: {
    // 触发弹窗
    openInformation() {
      this.visible = true
    },
    // 关闭弹窗
    cancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.right-flex {
  width: 45px;
  position: fixed;
  top: 75%;
  right: 0;
  z-index: 99;
  .flex-list {
    .flex-item {
      margin-bottom: 5px;
      position: relative;
      width: 56px;
      height: 56px;
      float: right;
      line-height: 56px;
      background: #FFFFFF;
      border-radius: 4px 0px 0px 4px;
      box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
      transition: width 0.3s, background 0.3s, margin 0.3s;
      cursor: pointer;
      .link {
        display: block;
        width: 100%;
        height: 100%;
          margin: 0 auto;
        .name {
          font-size: 18px;
          font-weight: bold;
          color: @theme;
          line-height: 56px;
          padding: 0 20px 0 56px;
        }
        & > .iconfont {
          color: @theme;
          font-size: 36px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: right 0.3s;
        }
      }
      &:hover {
        margin-right: 0;
        width: 172px;
        .link {
          .name {
            padding: 0 10px 0 24px;
          }
          & > .iconfont {
            right: -10px;
            left: auto;
          }
        }
      }
    }
  }

}
</style>
