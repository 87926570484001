<template>
  <div class="header-wrap">
    <header-pc class="show-header-pc" :header-nav="headerNav" />
    <header-mobile class="show-header-mobile" :header-nav="headerNav" />
  </div>
</template>

<script>
import HeaderPc from '@/components/layout/header/HeaderPc.vue'
import HeaderMobile from '@/components/layout/header/HeaderMobile.vue'
import { mapState } from 'vuex'
import { getHeaderApi } from '@/api/home.js'
export default {
  components: {
    HeaderPc,
    HeaderMobile
  },
  data() {
    return {
      headerNav: [] // 头部导航数据
    }
  },
  computed: {
    ...mapState({
      isToken: (state) => state.login.token // token信息
    })
  },
  watch: {
    isToken(val, oldVal) {
      if (val !== oldVal) {
        this.getHeaderNav()
      }
    },
    deep: true,
    immediate: true
  },
  mounted() {
    this.getHeaderNav()
  },
  methods: {
    // 获取头部导航接口
    getHeaderNav() {
      getHeaderApi().then((res) => {
        this.headerNav = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header-wrap {
  position: relative;
  margin-bottom: 80px;
  .show-header-mobile {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 60px;
    .show-header-pc {
      display: none;
    }
    .show-header-mobile {
      display: block;
    }
  }
}
</style>
