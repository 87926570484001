import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  region: '/user/region', // 国家
  verifyCode: '/VerifyCode/index', // 验证码
  contactUs: '/home/contact_us'// contact us接口
}

// 国家
export const regionApi = () => _axios.get(apiUrl.region)
//  验证码
export const verifyCodeApi = () => _axios.get(apiUrl.verifyCode)
// contact us接口
export const contactUsApi = (params) => _axios.post(apiUrl.contactUs, params)
